.email-block {
	background-color: $color-base;
	color: white;
	padding: 11px 0;

	&__notify {
		display: none;
		position: fixed;
		bottom: 0;
	}

	&__text {
		float: left;
		margin: 0;
		line-height: 40px;
	}

	&__form {
		float: right;
		@include clearfix;
		max-width: 100%;

		button {
			float: left;
			width: 40px;
			height: 40px;
			font-size: 19px;
			background-color: transparent;
			margin: 0 10px;
			color: #d44c3c;
		}

		input[type="text"] {
			float: right;
			width: 394px;
			max-width: calc(100% - 60px);
			height: 40px;
			background-color: white;
			margin-bottom: 0;

			&::placeholder {
				color: rgba(42,42,42,0.2);
			}
		}
	}

	@include media("<md") {
		&__text {
			float: none;
			text-align: center;
		}

		&__form {
			float: none;
			text-align: center;

			button, input {
				float: none !important;
			}
		}
	}
}