.home-box {
	padding-top: 55px;
	padding-bottom: 80px;

	&__heading {
		margin-bottom: 50px;
		text-align: center;
		padding: 0 15%;
	}

	&__title {
		margin: 0;
		font-weight: 600;
		font-size: 30px;
	}

	&__action {
		text-align: center;
	}

	&.index-news-box {
		background-color: white;
	}

	&.client-box {
		padding-bottom: 40px;
	}

	&.product-box {
		background-image: url("../imgs/bg-flower.jpg");
	}

	@include media("<md") {
		&__heading {
			padding: 0;
		}
	}
}