@mixin align-middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin clearfix {
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}