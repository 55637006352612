.detail-top, .detail-bottom {
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: 16px;

	@include media("<md") {
		flex-flow: row wrap;
	}
}

.detail-desc {
	flex: 0 1 450px;
	margin-right: 57px;
	background-color: $color-gray;
	padding-bottom: 30px;

	&__title {
		font-size: 15px;
		font-weight: normal;
		text-transform: uppercase;
		text-transform: none;
		padding: 15px 20px;
		border-bottom: 14px solid white;
		margin: 0;
	}

	&__text {
		padding: 15px 20px;
		padding-bottom: 0;
		margin: 0;
	}

	&__price {
		color: $color-orange;
		font-size: 22.5px;
		text-transform: none;
		margin: 40px 0;
		padding: 0 20px;
	}

	&__status {
		padding: 0 20px;

		b {
			color: $color-orange;
			font-size: 18px;
		}
	}

	@include media(">=md", "<lg") {
		flex: 0 1 40%;
		margin-right: 30px;
	}

	@include media("<md") {
		flex: 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}
}

.detail-img {
	flex: 0 1 calc(100% - 450px - 57px);
	overflow: hidden;

	@include media(">=md", "<lg") {
		flex: 0 1 calc(100% - 40% - 30px);
	}

	@include media("<md") {
		flex: 100%;
	}
}

.thumb-carousel {
	padding: 7px 51px;
	margin-top: 14px;
	background-color: $color-gray;

	&__item {
		cursor: pointer;
		border: 2px solid transparent;
	}

	&__prev, &__next {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		cursor: pointer;

		&.disabled {
			opacity: 0.2;
		}
	}

	&__prev {
		@include triangle(left, 20px, 10px, $color-base);
		left: 11px;

		&:hover {
			border-right-color: $color-hover;
		}
	}

	&__next {
		@include triangle(right, 20px, 10px, $color-base);
		right: 11px;

		&:hover {
			border-left-color: $color-hover;
		}
	}

	.owl-item.current &__item {
		border-color: $color-base !important;
	}
}

.detail-tabs {
	flex: 0 1 731px;
	margin-right: 75px;
	max-width: 100%;

	.tabs-link {
		background-color: $color-gray;
		border-bottom: 1px solid $color-orange;
		margin-bottom: 12px;

		> li {
			display: inline-block;
			margin-left: -4px;
			width: 50%;
			text-align: center;
			line-height: 62px;
			color: rgba (42,42,42,0.3);

			&:first-child {
				margin-left: 0;
			}

			a {
				display: block;
			}

			&.active {
				background-color: $color-base;
				color: white;
			}
		}
	}

	.tabs-panel {
		padding: 40px 55px;
		background-color: $color-gray;
		line-height: 1.6;
		display: none;

		> ul {
			columns: 2;
		}

		&#tab-1 {
			display: block;
		}

		&.map-panel {
			padding: 0;

			iframe {
				width: 100%;
				height: 313px;
			}
		}
	}

	@include media(">=md", "<lg") {
		margin-right: 30px;
	}

	@include media("<md") {
		flex: 0 0 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}
}

.detail-info {
	flex: 0 1 204px;
	margin-right: 97px;
	position: relative;
	padding: 12px 19px;
	background-color: $color-gray;

	&__name {
		margin: 10px 0 15px 0;
		padding-bottom: 5px;
		border-bottom: 1px solid #dee1e4;
		font-weight: normal;
		text-transform: none;
	}

	&__img {
		margin-bottom: 10px;
	}

	&__line {
		@include clearfix;
		padding: 5px 0;
		font-size: 11.25px;

		> i {
			float: left;
		}

		> a {
			float: right;
		}
	}
}

.detail-social {
	position: absolute;
	top: 50%;
	right: -30px;
	transform: translate(100%, -50%);
	border: 1px solid #d5d9dd;
	padding: 10px;
	background-color: $color-gray;

	a {
		display: block;
		width: 44px;
		height: 46px;
		line-height: 46px;
		text-align: center;
		border: 1px solid #d5d9dd;
		margin-bottom: 6px;
		color: #888888;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			color: $color-hover;
		}
	}
}

.detail-desc-text {
	padding: 15px 20px;
	max-height: calc(1.5em * 6 + 30px);
	overflow-y: auto;
	background-color: $color-gray;
	margin-bottom: 16px;
}