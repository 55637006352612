//include navtop

.header {
	
	&-middle {
		padding: 10px 0;
		position: relative;
		@include clearfix;
	}

	.logo {
		float: left;
		max-width: 300px;

		@include media("<lg") {
			max-width: 40%;
		}
	}

	.main-title {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		margin: 0;
		font-style: italic;
		font-weight: 300;
		text-transform: none;
		max-width: calc(100% - 350px);
		max-height: 28px * 3;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;

		@include media("<lg") {
			display: none;
		}
	}
}
