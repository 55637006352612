.product-layout {
	@include clearfix;
}

.product {
	float: left;
	width: calc((100% - 38px)/3);
	margin-right: 19px;
	margin-bottom: 19px;
	background-color: $color-gray;

	&:nth-child(3n+3) {
		margin-right: 0;
	}

	&__title {
		font-size: 15px;
		font-weight: normal;
		text-transform: none;
		padding: 0 20px;
		margin: 15px 0;
		height: 1.5em * 2;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	&__desc {
		padding: 15px 20px 10px 20px;
	}

	&__price {
		text-transform: none;
		color: $color-orange;
		font-size: 18px;
		margin: 0;
	}

	@include media("<sm") {
		width: 100%;
		margin: 0;
		margin-bottom: 15px;

		&__img {
			text-align: center;
		}

		&__title {
		}
	}
}