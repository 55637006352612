.btn {
	background-color: $color-base;
	color: white;
	border-radius: 20px;
	font-size: 14px;
	font-weight: 600;
	height: 40px;
	line-height: 40px;
	padding: 0 40px;
	border: 1px solid transparent;
	text-transform: uppercase;
	transition: all 0.3s;
	display: inline-block;

	&:hover {
		border: 1px solid $color-base;
		background-color: white;
		color: $color-base;
	}
}

.btn-link {
	color: $color-base;

	i {
		padding-left: 8px;
	}

	&:hover {
		color: black;
	}
}