* {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

body {
	position: relative;
}
// page wrap
.page-wrap {
	overflow: hidden;
}

.page-content {
	min-height: 748px;
}

.wrapper {
	margin: 0 auto;
	@include clearfix;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 768px) {
  .wrapper {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    width: 1137px;
  }
}

hr {
	border-top: 1px solid #dddddd;
}

img {
	max-width: 100%;
	height: auto;
}

button {
	padding: 0;
	border: 0;
	outline: none;
}


input {
	// outline: none;
	border: none;
}

a:hover {
	color: $color-hover;
	text-decoration: none;
	transition: color 0.3s ease;
}

a:focus {
	color: inherit;
}

ul {
	padding: 0;
	margin: 0;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

a, button {
	cursor: pointer;
}