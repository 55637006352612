input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="url"],
select,
textarea {
	height: 40px;
	background-color: #f7f7f7;
	color: black;
	padding: 0 15px;
	width: 100%;
	border-radius: 10px;
	border: none;
	outline: none;
	margin-bottom: 14px;
	text-align: center;

	&::placeholder {
		color: rgba(83,79,79,0.7);
		text-align: center;
	}
}

textarea {
	min-height: 12em;
	max-width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
}



.form {
	@include clearfix;

	&__select {
		position: relative;
		background-color: #f7f7f7;
		border-radius: 10px;
		margin-bottom: 14px !important;
		text-align: center;

		&:after {
			content: "\f107";
			font-family: "FontAwesome";
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translate(0, -50%);
			// z-index: -1;
		}

		select {
			color: rgba(83,79,79,0.7);
			appearance: none;
			background-color: transparent;
			position: relative;
			z-index: 1;
			margin-bottom: 0;
			text-align-last: center;

			option {
				text-align: center;
				color: black;

				&:disabled {
					color: rgba(83,79,79,0.4);
				}
			}
		}
	}

	&__checkbox {

		label {
			position: relative;
			padding-left: 26px;
			display: flex;
			
			input[type="checkbox"] {
				visibility: hidden;
				position: absolute;
			    left: 0;
			    top: 50%;
		        transform: translate(0, -50%);

				&:before {
				    content: " ";
				    background-color: white;
				    border: 1px solid #677a78;
				    // box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
				    // padding: 6px;
				    width: 12px;
				    height: 12px;
				    top: -3px;
				    // border-radius: 3px;
				    display: inline-block;
				    position: relative;
				    margin-top: -2px;
				    visibility: visible;
				}
				
				&:checked {
					&:after {
					    content: '\2714';
					    font-size: 12px;
					    position: absolute;
					    top: -2px;
					    left: 1px;
					    color: $color-orange;
					    opacity: 1;
					    visibility: visible;
					}
				}
			}
		}
	}

	&__file {
		position: relative;
		margin-bottom: 20px;
		text-align: center;

		label {
			display: inline-block;
			height: 40px;
			line-height: 40px;
			padding: 0 40px;
			background-color: $color-gray;
			border-radius: 10px;
			cursor: pointer;
			margin-right: 30px;
		}

	    input[type="file"] {
		    width: 0.1px;
		    height: 0.1px;
		    opacity: 0;
		    overflow: hidden;
		    position: absolute;
		    z-index: -1;
	    }

	    @include media("<sm") {
	    	label {
	    		margin-right: 0;
	    	}

	    	span {
	    		display: block;
	    	}
	    }
	}

	&__action {
		float: left;
		width: 100%;
		text-align: center;
		position: relative;
	}

	button {
		padding: 0 90px;
		height: 40px;
		border-radius: 10px;
		background-color: $color-orange;
		font-weight: bold;
		color: white;
		font-size: 18px;
		box-shadow: 0 0 18px 3px rgba(0,0,0,0.24);

		@include media("<576px") {
			width: 50% !important;
			padding: 0;
		}
	}

	&__width-half {
		float: left;
		width: calc(50% - 10px);
		margin: 0 10px;

		&:nth-of-type(2n+1) {
			margin-left: 0;
		}

		&:nth-of-type(2n+2) {
			margin-right: 0;
		}

		@include media("<md") {
			float: none;
			width: 100%;
			margin: 0;
		}
	}

	&__width-one-third {
		float: left;
		width: calc((100% - 40px)/3);
		margin: 0 10px;

		&:nth-child(3n+1) {
			margin-left: 0;
		}

		&:nth-child(3n+3) {
			margin-right: 0;
		}

		@include media("<md") {
			float: none;
			width: 100%;
			margin: 0;
		}
	}
}