.breadcrumb {
	background-color: white;
	display: inline-block;
	border-radius: none;
	// border-top: 1px solid #dddddd;
	// border-bottom: 1px solid #dddddd;
	padding: 10px 0;
	margin-top: 15px;
	padding-right: 40px;

	&__item {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 18px;
		padding: 0 10px;
		position: relative;
		transition: all 0.3s;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			width: 2px;
			height: 16px;
			background-color: $color-base;
			transform: translate(0, -50%) skew(-20deg);
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child:after {
			content: none;
		}

		&:hover {
			color: $color-hover;
		}
	}
}