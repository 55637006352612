.color-white {
	color: white;
}

.color-base {
	color: $color-base;
}

.bg-color-base {
	background-color: $color-base;
}

.bg-green {
	background-color: #68b96f;

	.home-box {
		&__heading, &__title {
			color: white;
		}

		&__action .btn {
			border: 1px solid white;
		}
	}
}

.bg-white {
	background-color: white;
}