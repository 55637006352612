// Font

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}



$font-base: 'Montserrat', sans-serif;
// $font-subbase: 'Open Sans','italic', serif;

// Font size
$font-size-base: 15px;
$font-size-subbase: 14px;
$font-size-icon: 15px;
$font-size-h1: 20px;
$font-size-h2: 18px;
$font-size-h3: 16px;
$font-size-h4: 15px;
$font-size-h5: 14px;
$font-size-h6: 13px;

// Color
$color-base: #2a2a2a;
// $color-subbase: #207400;
$color-text-base: #2a2a2a;
$color-border: #ececec;
$color-gray: #f7f7f7;
$color-hover: #ff5441;
// $color-green-light: #6db869;
// $color-green-bold: #207400;
$color-dark: #2a2a2a;
$color-orange: #ff5441;

// Responsive
$breakpoints: (
    xs: 320px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
    xxl: 1600px
) !default;

// Z-index
$z-layers: (
    default: 0,
    dropdown: 1000,
    navbar: 1000,
    fixed: 1030,
    sticky: 1030,
    popover: 1060,
    tooltip: 1070
) !default;