.contact {
	margin-bottom: 100px;

	&__left {
		float: left;
		width: 50%;
		padding-right: 25px;

		iframe {
			width: 100%;
			height: 390px;
		}

		@include media("<md") {
			float: none;
			width: 100%;
			padding-right: 0;
		}
	}

	.contact-form {
		float: right;
		width: 50%;
		padding-left: 25px;
		padding-top: 80px;

		input, textarea {
			text-align: left;

			&::placeholder {
				text-align: left;
				padding-left: 40px;
			}
		}

		button {
			margin-top: 60px;
		}

		@include media("<md") {
			float: none;
			width: 100%;
			padding-left: 0;
		}
	}

	&__text {
		padding-left: 5%;
		margin-bottom: 30px;

		a[class*="btn-"] {
			display: inline-block;
			width: 20px;
			height: 21px;
			background-image: url("../imgs/icons.png.png");
			background-size: auto;
			margin-right: 12px;
		}

		.btn-email {
			background-image: none !important;

			i {
				font-size: 20px;
			}
		}
	}

	&__title {
		color: $color-orange;
	}

	&__line {
		padding: 10px 0;
	}
}