.client-carousel {
	padding: 0 130px;
	margin-top: 40px;
	margin-bottom: 40px;
	
	&__prev, &__next {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		cursor: pointer;
	}

	&__next {
		@include triangle(right, 20px, 10px, $color-base);
		right: 55px;
	}

	&__prev {
		@include triangle(left, 20px, 10px, $color-base);
		left: 55px;
	}

	@include media("<md") {
		padding: 0 40px;

		&__next {
			right: 15px;
		}

		&__prev {
			left: 15px;
		}
	}
}

.client-item {
	display: flex;
	flex-flow: row nowrap;

	&__img {
		flex: 0 1 236px;
	}

	&__right {
		position: relative;
		flex: 0 1 calc(100% - 236px);
	}

	&__desc {
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translate(0, -50%);
		text-align: center;
	}

	&__name {
		font-weight: normal;
		text-transform: none;
	}

	@include media("<md") {
		display: block;

		&__desc {
			position: static;
			transform: none;
		}

		&__img {
			width: 236px;
			margin: 0 auto;
		}
	}
}