body {
	font-family: $font-base;
	font-size: $font-size-base;
	font-weight: normal;
	color: $color-text-base;
	line-height: 1.5;
	// letter-spacing: 0.02em;
}


h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	font-family: $font-base;
	text-transform: uppercase;
	color: $color-base;

	a {
		color: inherit;
	}
}

h1 {
	font-size: $font-size-h1;
}
h2 {
	font-size: $font-size-h2;
}
h3 {
	font-size: $font-size-h3;
}
h4 {
	font-size: $font-size-h4;
}
h5 {
	font-size: $font-size-h5;
}
h6 {
	font-size: $font-size-h6;
}

.text-upper {
	text-transform: uppercase;
}

.title-lv-1 {
	font-size: 30px;

	@include media("<md") {
		font-size: 24px !important;
	}
}