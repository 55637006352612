.menu-bar {
	background-color: $color-dark;
	color: white;

	@include media("<lg") {
		padding: 40px 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 9999;
		overflow-y: scroll;
		display: none;
	}

	.exit-menu-bar {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 20px;

		@include media(">=lg") {
			display: none;
		}
	}
}

.menu {
	display: flex;
	flex-flow: row nowrap;
	max-width: 100%;

	&__item {
		flex-grow: 1;
		flex-shrink: 1;
		height: 62px;
		max-height: 62px;
		line-height: 62px;
		position: relative;

		> a {
			display: block;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			padding: 0 30px;
			height: 100%;
			width: 100%;

			@include media(">=lg", "<xl") {
				padding: 0 20px;
			}
		}

		.open-sub {
			top: 6px !important;
		}

		&:hover {
			> .menu__sub {
				display: block;
			}
		}
	}

	&__sub {
		display: none;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0);
		background-color: #393939;
		width: 170px;
		text-align: center;
		padding: 5px 0;
		z-index: 99;
	}

	&__sub-item {
		height: normal;
		line-height: normal;
		position: relative;

		> a {
			display: block;
			padding: 5px 15px;
		}

		> .menu__sub {
			display: none;
			position: absolute;
			top: -5px;
			left: 100%;
			transform: translate(0, 0);
		}

		.open-sub {
			top: 4px !important;
		}

		&:hover {
			> .menu__sub {
				display: block;
			}
		}
	}

	.open-sub {
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0;

		@include media(">=lg") {
			display: none;
		}
	}

	@include media("<lg") {
		display: block;

		&__sub {
			border-left: 2px solid $color-orange;
			background-color: transparent;
			// display: block;
			position: static;
			transform: translate(0, 0);
			width: 100%;
			text-align: left;
			padding-left: 15px;
			font-size: 14px;
		}

		&__item {
			height: unset;
			line-height: 1.5em;
			text-align: left;
			overflow: visible;
			max-height: unset;

			a {
				padding: 10px 0;
			}

			&:hover {
				> .menu__sub {
					display: none;
				}
			}
		}

		&__sub-item {
			> .menu__sub {
				position: static;
				transform: translate(0, 0);
				font-size: 13px;
			}

			&:hover {
				> .menu__sub {
					display: none;
				}
			}
		}
	}
}

.menu-btn {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	font-size: 22px;
	color: $color-orange;

	@include media(">=992px") {
		display: none;
	}
}