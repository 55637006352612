.tabs-link {
	margin-bottom: 20px;
	text-align: center;

	&__item {
		display: inline-block;
		margin-left: -4px;
		text-transform: uppercase;
		margin: 0 7.5px;
		margin-bottom: 10px;
		padding: 5px 20px;
		border-radius: 20px;
		background-color: white;
		color: #353535;
		transition: all 0.3s;
		border: 1px solid transparent;

		&:first-child {
			margin-left: 0;
		}

		&.active {
			background-color: $color-base;
			color: white;

			&:hover {
				background-color: white;
				border: 1px solid $color-base;

				a {
					color: $color-base;
				}
			}
		}

		&:hover {
			border: 1px solid $color-base;

			a {
				color: $color-base;
			}
		}
	}
}