.article {
	margin-bottom: 40px;
}

.article-comment {
	margin: 30px 0;

	&__heading {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding: 20px 0;
		border-top: 1px solid #dddddd;

		@include media("<sm") {
			flex-flow: row wrap;		
		}
	}

	&__title {
		margin: 0;
		color: $color-base;
		margin-right: 50px;
	}

	&__btn {
		margin-top: 2px;
	}
}

.fb-comments > span {
	background-color: white;
}