.nav-top {
	@include clearfix;
	background-color: $color-dark;
	color: white;
	padding: 3.5px 0;

	@include media("<lg") {
		margin-top: 30px;	}
}

.social-left {
	float: left;
	@include clearfix;

	> * {
		float: left;
		margin: 0 7px;
	}

	> a[class^="btn-"] {
		width: 20px;
		height: 21px;
		background-image: url("../imgs/icons.png.png");
		background-size: auto;
	}

	@include media("<lg") {
		line-height: 2em;
		float: none;
		text-align: center;

		> * {
			display: inline-block;
			float: none;
		}
	}
}

.social-email {
	margin-left: 35px;

	i {
		font-size: 17px;
		color: white;
		padding-right: 15px;
	}
}

.btn-call {
	background-position: 0 0;
}

.btn-zalo {
	background-position: -32px 0;
}

.btn-viber {
	background-position: -65px 0;
}

.btn-whatsapp {
	background-position: -96px 0;
}

.btn-youtube {
	background-position: -146px 0;
	width: 27px !important;
}

.btn-facebook {
	background-position: -187px 0;
	margin-left: 29px;
}

.social-right {
	float: right;

	> a {
		float: left;
		width: 20px;
		height: 21px;
		background-image: url("../imgs/icons.png.png");
		background-size: auto;
	}

	@include media("<lg") {
		line-height: 2em;
		float: none;
		text-align: center;

		> a {
			display: inline-block;
			float: none;
			vertical-align: middle;
		}
	}
}

.fixed {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
}