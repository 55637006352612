.pagination {
	display: block;

	> li {
		display: inline-block;
		text-align: center;
		margin: 0 3px;
		margin-bottom: 5px;

		> a, > span {
			float: none;
			line-height: 1.7em;
			margin-left: 0;
			padding: 0;
			border: none;
			display: block;
			width: 26px;
			height: 26px;
			line-height: 26px;
			// border-radius: 50% !important;
			background-color: $color-gray;
			transition: all 0.3s;
			color: #353535;
			border: 1px solid transparent;
		}

		&:first-child a, &:last-child a {
			border-radius: unset;
		}

		&.active > a, &.active > span {
			border-color: transparent;
			background-color: $color-gray;
			color: $color-orange;

			&:hover, &:focus {
				border-color: transparent;
				color: $color-orange;
				background-color: $color-gray;
			}
		}

		&:hover > a, &:hover > span {
			color: $color-orange;
			border-color: transparent;
		}
	}

	&.align-right {
		text-align: right;
	}

	&.align-center {
		text-align: center;
	}

	&.align-left {
		text-align: left;
	}
}