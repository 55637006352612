.filter-form {
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0 45px;

	&__action {
		width: calc(100% + 90px);
		margin-left: -45px;
		margin-right: -45px;
		padding: 15px 0;
		}

	button {
		width: 320px;
		max-width: 100%;
	}
}