.footer {
	background-color: $color-base;
	color: white;
	padding: 30px 0;
	line-height: 2.5em;

	&__title {
		color: white;
		margin-bottom: 30px;
	}

	&__left {
		float: left;
		width: 50%;
		padding-right: 15px;
	}

	&__right {
		float: right;
		width: 50%;
		padding-left: 15px;
		text-align: right;

		p {
			margin: 0;
		}
	}

	&__nav {
		> li {
			display: inline-block;
			margin-left: 50px;
		}
	}

	@include media("<md") {
		&__left, &__right {
			float: none;
			width: 100%;
			padding: 0;
			text-align: left;
		}

		&__left {
			margin-bottom: 40px;
		}

		&__nav {
			> li:first-child {
				margin-left: 0;
			}
		}
	}
}