.landlord-form {
	margin-bottom: 100px;

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		text-align: left;
		&::placeholder {
			text-align: left;
			padding-left: 40px;
		}
	}
	&__checkbox-block {
		@include clearfix;
		margin: 0 20%;
		padding: 35px 30px;
		background-color: $color-gray;
		margin-bottom: 35px;

		@include media("<lg") {
			margin-left: 0;
			margin-right: 0;
		}

	}

	> * {
		@include clearfix;
	}

	&__input-block {
		margin-bottom: 20px;
	}

	&__input-bottom {
		width: 525px;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 30px;
	}

	&__file {
		position: relative;
		margin-bottom: 60px;
	}

	&__file-title {
		position: absolute;
		top: 0;
		left: 0;

		@include media("<md") {
			position: static;
			display: inline-block;
			margin-bottom: 15px;
		}
	}

	&__text {
		padding-left: 5%;
		margin-bottom: 60px;
	}

}