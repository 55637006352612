.page-box {
	margin-bottom: 20px;

	&__title {
		position: relative;
		margin-bottom: 5px;

		h2 {
			display: inline-block;
			padding-right: 50px;
			background-color: white;
		}
	}
}